import React, { useEffect } from 'react';
import { AppBar, Box, Toolbar, Stack, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo_Vesto.svg';
import { useAuth } from '../../AuthContext';
import { colors } from '../../theme';
import { products } from '../utils/products/products';

export default function CustomAppBar() {
  const { filteredGender, setFilteredGender, setFilteredProducts } = useAuth();
  const location = useLocation(); // Get current path

  useEffect(() => {
    if (filteredGender === "all") {
      setFilteredProducts(products);
    } else {
      const temp = products.filter(
        (product) => product.gender === filteredGender
      );
      setFilteredProducts(temp);
    }
  }, [filteredGender]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '0 20px',
          }}
        >
          <Box
            component={Link}
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              marginRight: location.pathname === '/' ? '30px' : 0, // Add margin only on root
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="Vesto Logo"
              sx={{ height: '30px' }}
            />
          </Box>

          {location.pathname === '/' && ( // Show Stack only on root
            <Stack
              direction="row"
              spacing={2}
              sx={{ flexGrow: 1 }}
            >
              <Button
                variant="secondVariant"
                onClick={() => setFilteredGender("all")}
                sx={{
                  background: filteredGender === "all" ? colors.black : colors.backgroundBlack,
                }}
              >
                Products
              </Button>
              <Button
                variant="secondVariant"
                onClick={() => setFilteredGender("female")}
                sx={{
                  background: filteredGender === "female" ? colors.black : colors.backgroundBlack,
                }}
              >
                Female
              </Button>
              <Button
                variant="secondVariant"
                onClick={() => setFilteredGender("male")}
                sx={{
                  background: filteredGender === "male" ? colors.black : colors.backgroundBlack,
                }}
              >
                Male
              </Button>
            </Stack>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
