import { createTheme} from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';

export const colors = {
  // primary: '#016F72',
  // secondary: '#ED643D',
  primary: "#3758F9",
  secondary: "#02AAA4",
  white: '#fff',
  black: '#323232',
  lightBlack: '#637381',
  lightGray: '#DFE4EA',

  backgroundBlack: "#231F20",
  backgroundLight: "#F6F6FF",

  border: "#D5DAE0",

  yellow: '#FAC748',
  darkGreen: '#014344',
  lightGray: '#D8E9E9',
  borderGray: '#C6C6C6',
  iconGray: '#7A9595',
  disabledGray: '#689FA1',
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      // default: colors.primary, 
      // paper: colors.white,
    },
    primary: {
      main: colors.primary, 
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.black,
      secondary: colors.white, 
    },
    custom: {
      yellow: colors.yellow, 
      darkGreen: colors.darkGreen, 
      lightGray: colors.lightGray, 
      borderGray: colors.borderGray,
      iconGray: colors.iconGray, 
      disabledGray: colors.disabledGray,
      white: colors.white
    },
  },
  typography: {
    fontFamily: 'Poppins, Montserrat, sans-serif',
    h1: {
      fontSize: '26px',
      fontWeight: '700',
      color: colors.black
    },
    h2: {
      fontSize: '20px',
      fontWeight: '600',
      color: colors.black
    },
    h3: {
      fontSize: '18px',
      fontWeight: 'normal',
      color: colors.black
    },
    h4: {
      fontSize: '16px',
      fontWeight: 'normal',
      color: colors.black
    },
    h5:{
      fontSize: '34px',
      fontWeight: '600',
      color: colors.black
    },
    h6:{

    },
    body1: {
      fontSize: '12px',
      fontWeight: 'normal',
      color: colors.black,
      lineHeight: '20px'
    },
    body2: {
      fontSize: '12px',
      fontWeight: 'normal',
      color: colors.black,
      lineHeight: '24px'
    },
    caption: {
      fontSize: '10px',
      fontWeight: 'normal',
      color: colors.black,
      lineHeight: '16px'
    },
    button: {
      textTransform: 'none',
      fontWeight: 'normal',
    },
  },
  components: {
    MuiTypography: {
      display: 'block',
     },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none', 
          },
          '&:active': {
            boxShadow: 'none', 
          },
          width: '108px',
          height: '41px',
          borderRadius: '6px',
          gap: '10px',
          backgroundColor: colors.primary, 
          color: colors.white,
          fontSize: '13px', 
          fontWeight: 'normal', 
          lineHeight: '24px',
          '&.Mui-disabled': {
            color: colors.lightGray,
            border: `2px solid ${colors.lightGray}`
          },
        },
        text:{
        },
        secondVariant: {
          width: '81px',
          height: '33px',
          gap: '10px',
          borderRadius: '6px',
          border: `none`, 
          backgroundColor: colors.backgroundBlack,
          color: colors.white,
          fontSize: '13px',
          fontWeight: 'normal',
          lineHeight: '30px',
          padding: '12px 16px'
        },
        thirdVariant: {
          width: '166px',
          height: '50px',
          gap: '10px',
          borderRadius: '6px',
          border: `none`, 
          backgroundColor: colors.secondary,
          color: colors.white,
          fontSize: '14px',
          fontWeight: 'normal',
          lineHeight: '30px',
          padding: '20px 30px'
        },
        forthVariant: {
          width: '46px',
          height: '44px',
          gap: '10px',
          borderRadius: '5px',
          border: `2px solid ${colors.border}`, 
          backgroundColor: 'transparent',
          color: colors.black,
          fontSize: '14px',
          fontWeight: 'normal',
          lineHeight: '30px',
          padding: '10px 14px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: colors.backgroundBlack, 
          height: "64px"
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '340px',                
          height: '48px',               
          borderRadius: '50px',  
          backgroundColor: colors.white, 
          color: colors.black, 
          // border: `1px solid ${colors.lightGray}`, 
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.black, 
          width: '340px',                
          height: '48px',      
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '340px',                
          height: '48px',     
          borderRadius: '50px',  
        },
        select: {
          color: colors.black, 
          '&:focus': {
            backgroundColor: 'transparent', 
          },
        },
        icon: {
          color: colors.iconGray, 
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          width: '340px',             
          height: '145px',           
          gap: '5px',                 
          borderRadius: '6px',    
          border: `1px solid ${colors.borderGray}`, 
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingLeft: '8px', 
          '&.Mui-selected': {
            backgroundColor: colors.lightGray, 
            '&:hover': {
              backgroundColor: colors.lightGray, 
            },
          },
          '&:hover': {
            backgroundColor: colors.lightGray, 
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root:first-of-type': {
            backgroundColor: colors.lightGray, 
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: '14px',
          fontFamily: 'Poppins, sans-serif',
        },
        root: {
          width: '270px',                
          height: '48px',    
          borderRadius: '50px',
          border: `1.5px solid ${colors.secondary}`, 
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1.5px solid ${colors.secondary}`, 
          },
        },
        notchedOutline: {
          borderColor: `1.5px solid ${colors.borderGray}`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
          paper: {
              borderRadius: '6px',
          },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
          root: {
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: '18px',
              color: colors.white,
              backgroundColor: colors.primary,
          },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
          root: {
              border: 'none', 
              // height: '140px',
              padding: '20px'
          },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
          root: {
              justifyContent: 'center',
              display: 'flex',
              flexDirection:'column',
              '& .MuiButton-root': {
                  width: '275px', 
              },
          },
      },
    },
  },
});

export default theme;

export const globalStyles = (
  <GlobalStyles
    styles={{
      'html, body, #root': {
        // height: '100vh',
        width: '100vw',
        // overflow: 'hidden', 
        // touchAction: 'none', 
      },
    }}
  />
);
