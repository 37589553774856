import React, { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAuth } from '../../AuthContext';

const PersistentIframe = () => {
  const { avatarUrl, iframeRefAvatar } = useAuth()

  // useEffect(() => {
  //   console.log('Iframe mounted only once');
  //   sendPoseMessage()
  // }, []);

  return (
    <Box
        component="iframe"
        ref={iframeRefAvatar}
        src={avatarUrl}
        title="Persistent Iframe"
        width="600px"
        height="699px"
        sx={{
            position: 'fixed',  
            left: 115,         
            top: '474px',        
            transform: 'translateY(-50%)',
            border: 'none',
            zIndex: 1000,      
            border: '2px solid #DFE4EA', 
            borderRadius: '10px' ,
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0'
        }}
    />
  );
};

export default PersistentIframe;
