import React, { createContext, useState, useContext, useRef } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [showAvatar, setShowAvatar] = useState(false)
  const [initialUrl, setInitialUrl] = useState("https://clothes3d-9be15.web.app/?")
  const [avatarUrl, setAvatarUrl] = useState("https://clothes3d-9be15.web.app/?")
  const [givenSize, setGivenSize] = useState("")
  const [avatarParams, setAvatarParams] = useState("")
  const [availableSizes, setAvailableSizes] = useState(["XS","S","M","L","XL","XXL","XXXL"])
  const [filteredGender, setFilteredGender] = useState("all");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const iframeRefAvatar = useRef(null);

  const value = {
    initialUrl,
    showAvatar,
    setShowAvatar,
    avatarUrl,
    setAvatarUrl,
    iframeRefAvatar,
    givenSize,
    setGivenSize,
    avatarParams,
    setAvatarParams,
    availableSizes,
    setAvailableSizes,
    filteredGender,
    setFilteredGender,
    filteredProducts,
    setFilteredProducts
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}