import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Typography, Button, Grid, Switch } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CustomAppBar from '../CustomAppBar';
import { products } from '../utils/products/products';
import { useAuth } from '../../AuthContext';
import { colors } from '../../theme';

import logo_esenca from '../../assets/images/logo_esenca.png'
import tension_meter from '../../assets/images/tension.svg'

const ProductPage = () => {
  const iframeRef = useRef(null);
  const { availableSizes, setAvailableSizes, initialUrl, avatarParams, setAvatarParams, givenSize, setGivenSize, showAvatar, setShowAvatar, avatarUrl, setAvatarUrl, iframeRefAvatar } = useAuth()

  const [showMeasurementModule, setShowMeasurementModule] = useState(false)
  const [selectedSize, setSelectedSize] = useState("")

  const { productId } = useParams();
  const product = products.find((p) => p.id === parseInt(productId));

  const [isTensionActive, setIsTensionActive] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState([])

  const [mixedItem, setMixedItem] = useState("")

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [product3dLink, setProduct3dLink] = useState("")

  useEffect(() => {
    if (product && product.src) {
      setProduct3dLink(product.src)
    }
  }, [product])

  useEffect(() => {
    if (givenSize) {
      setSelectedSize(givenSize)

      let tempUrl = initialUrl + avatarParams + "&itemIds=" + product.id
      setAvatarUrl(tempUrl)

      setShowAvatar(true)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowMeasurementModule(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowMeasurementModule]);


  useEffect(() => {
    const relatedProducts = products.filter(p =>
      product?.mixMatch.includes(p.id)
    );
    setRecommendedProducts(relatedProducts)
  }, [product])

  useEffect(() => {
    const handleMessage = (event) => {
      // if (event.origin !== 'https://iframe-origin.com') return; // Replace with iframe's origin
      if (event.data.type === 'postResponse') {
        let params = event.data.data.params
        setAvatarParams(params)

        let paramsUrl = new URLSearchParams(params);
        let selectedSize = paramsUrl.get('size');
        setGivenSize(selectedSize)
        setSelectedSize(selectedSize)

        let startIndex = product.sizes.indexOf(selectedSize);
        let availableSizes = product.sizes.slice(startIndex);
        setAvailableSizes(availableSizes)

        let tempUrl = avatarUrl + params + "&itemIds=" + product.id
        setAvatarUrl(tempUrl)

        setShowMeasurementModule(false)
        setShowAvatar(true)
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  if (!product) {
    return <Typography variant="h5">Product not found.</Typography>;
  }

  const handleSizeClick = (size) => {
    if (showAvatar && isButtonDisabled) return;

    setSelectedSize(size);
    setIsButtonDisabled(true);

    if (setShowAvatar) {
      if (!mixedItem) {
        const message = { type: 'postMessage', data: { sizes: [size], itemIds: [product.id] } };
        if (iframeRefAvatar.current) {
          iframeRefAvatar.current.contentWindow.postMessage(message, '*');
        }
      } else {
        const message = { type: 'postMessage', data: { sizes: [size, givenSize], itemIds: [product.id, mixedItem] } };
        if (iframeRefAvatar.current) {
          iframeRefAvatar.current.contentWindow.postMessage(message, '*');
        }
      }
    }

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  const handleTension = () => {
    setIsTensionActive((prev) => !prev);
    const message = { type: 'postMessage', data: { tension: !isTensionActive } };
    if (iframeRefAvatar.current) {
      iframeRefAvatar.current.contentWindow.postMessage(message, '*');
    }
  };

  const handleOpenMeasurement = () => {
    setShowMeasurementModule(true)
  };

  const handleMixMatchProducts = (index) => {
    console.log("clicked")
    if (setShowAvatar) {
      setMixedItem(index)
      const message = { type: 'postMessage', data: { sizes: [selectedSize, givenSize], itemIds: [product.id, index] } }
      if (iframeRefAvatar.current) {
        iframeRefAvatar.current.contentWindow.postMessage(message, '*');
      }
    }
  };

  return (
    <>
      <CustomAppBar />
      <Box sx={{ width: "100vw", height: "100vh", overflow: "hidden", ml: '115px', mr: '115px' }}>
        <Grid container sx={{ mt: '60px' }} spacing={0}>
          <Grid item xs={12} md={4}>
            <Box
              component="iframe"
              // src={product.src}
              src={product3dLink}
              title="Product Video"
              width="100%"
              height="700px"
              sx={{ border: '2px solid #DFE4EA', borderRadius: '10px', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
            ></Box>
          </Grid>

          <Grid item xs={12} md={2}>
            {/* Recommendations */}
            {recommendedProducts.length > 0 && (
              <>
                <Box sx={{
                  border: '2px solid #DFE4EA',
                  background: colors.backgroundLight,
                  borderLeft: 'none',
                  borderRadius: '10px',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  height: '700px', marginRight: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center'
                }}>
                  <Typography variant={'h4'} sx={{ fontWeight: "bold", mt: '20px', align: 'center' }}>Recommendations</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      mt: '30px'
                    }}
                  >
                    {recommendedProducts.map((product) => (
                      <Box
                        onClick={() => handleMixMatchProducts(product.id)}
                        key={product.id}
                        sx={{
                          width: '150px',
                          position: 'relative', // Make this position relative to place overlay on top
                          height: '100%',
                          cursor: 'pointer',
                          zIndex: '100'
                        }}
                      >
                        <Box
                          component="iframe"
                          src={product.src}
                          title={`${product.name} Video`}
                          sx={{
                            width: '100%',
                            height: '140px',
                            borderRadius: '4px',
                            border: `1px solid ${colors.borderGray}`,
                            overflow: 'hidden',
                            pointerEvents: 'none', // Prevent iframe from blocking clicks
                          }}
                        ></Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h1" mt={2}>
              {product.name}
            </Typography>
            <Typography variant="body2" mt={1} sx={{ width: "700px" }}>
              {product.description}
            </Typography>
            <Typography variant="h2" mt={1}>
              {product.price}
            </Typography>

            <Box mt={'50px'}>
              <Typography variant="h2">Select Size</Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: '20px' }}>
                {product.sizes.map((size, index) => (
                  <Button
                    key={index}
                    variant="forthVariant"
                    onClick={() => handleSizeClick(size)}
                    disabled={!availableSizes.includes(size)}
                    sx={{
                      border: (selectedSize && selectedSize === size) ? `2px solid ${colors.black}` : `2px solid ${colors.borderGray}`,
                      background: (selectedSize && selectedSize === size) ? colors.black : "inherit",
                      color: (selectedSize && selectedSize === size) ? colors.white : "inherit"
                    }}
                  >
                    {size}
                  </Button>
                ))}
              </Box>
            </Box>

            <Box mt={'30px'} sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {givenSize ?
                <Button variant="thirdVariant">
                  Your size is: {givenSize}
                </Button>
                :
                <Button variant="thirdVariant" onClick={handleOpenMeasurement}>Try it out</Button>
              }
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center' }}>
                {/* <Typography variant="body2" ml={'10px'}>based on <b>Fit Predictor</b></Typography> */}
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
                  <Typography variant="body2" ml={'10px'}>powered by</Typography>
                  <img src={logo_esenca} style={{ width: '70px', height: "20px" }} />
                </Box>
              </Box>
            </Box>

            {givenSize && (
              <>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 3, alignItems: 'center' }}>
                  <Switch
                    checked={isTensionActive}
                    onChange={() => {
                      handleTension();
                    }}
                    inputProps={{ 'aria-label': 'Toggle Tension Map' }}
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        color: colors.borderGray,
                      },
                      '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                        backgroundColor: colors.borderGray,
                      },
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: colors.secondary,
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#3DA39A',
                      },
                      '& .MuiSwitch-track': {
                        backgroundColor: '#DFE4EA',
                      },
                    }}
                  />
                  <Typography variant="body2">Tension Map</Typography>
                </Box>

                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 3, minHeight: '50px' }}>
                  {isTensionActive &&
                    <>
                      <img src={tension_meter} style={{ width: '266px', height: '44px' }} alt="Tension Meter" />
                      <Typography variant="body1" sx={{ width: '400px' }}>
                        It shows where the fabric is under more tension, indicating spots that may feel too tight or uncomfortable
                      </Typography>
                    </>
                  }
                </Box>
              </>
            )}


            <Box mt={givenSize ? 3 : 20}>
              <Button sx={{ width: '520px', height: '50px' }}>Add to Cart</Button>
            </Box>
          </Grid>
          {/* {(givenSize && recommendedProducts.length > 0) &&
            <Grid item xs={12} md={7} mt={2}>
              <Typography variant={'h4'} sx={{ fontWeight: "bold" }}>Recommendations</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 3,
                }}
              >
                {recommendedProducts.map((product) => (
                  <Box
                    key={product.id}
                    sx={{
                      position: 'relative',
                      width: '15%', 
                      height: '140px',
                    }}
                  >
                    <Box
                      component="iframe"
                      src={product.src}
                      title={`${product.name} Video`}
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '4px',
                        border: `1px solid ${colors.borderGray}`,
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                      }}
                    ></Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleMixMatchProducts(product.id)}
                    ></Box>
                  </Box>
                ))}
              </Box>

            </Grid>
          } */}
        </Grid>
        {showMeasurementModule &&
          <>
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 999,
              }}
              onClick={() => setShowMeasurementModule(false)}
            ></Box>
            <Box mt={4}>
              <Box
                sx={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1000,
                }}
              >
                <IconButton
                  onClick={() => setShowMeasurementModule(false)}
                  sx={{
                    position: 'absolute',
                    top: '-40px',
                    right: '-40px',
                    color: 'white',
                    zIndex: 1001,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Box
                  component="iframe"
                  ref={iframeRef}
                  src={"https://widget.dev.esenca.app/vesto/v2/"}
                  title="Product Widget"
                  width="400px"
                  height="700px"
                  sx={{
                    border: 'none',
                  }}
                ></Box>
              </Box>
            </Box>
          </>
        }
      </Box>
    </>
  );
};

export default ProductPage;
