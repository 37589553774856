import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import './App.css';

import HomePage from './components/Home';
import ProductPage from './components/Product';

import PersistentIframe from './components/PersistentIframe';

import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';


function App() {
  const location = useLocation();
  const {showAvatar, setShowAvatar} = useAuth()

  useEffect(()=>{
    const isShown = location.pathname.includes('/products/');
    if(!isShown){
      setShowAvatar(false)
    }
  },[location])
  // const isShown = true

  return (
    <>
      {showAvatar && (
        <Box sx={{ position: 'fixed', top: 0, width: '100%', zIndex: 10 }}>
          <PersistentIframe />
        </Box>
      )}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products/:productId" element={<ProductPage />} />
      </Routes>
    </>
  );
}

export default App;
