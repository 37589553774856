import React, { useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Button, Box, Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { products } from '../../utils/products/products';
import { colors } from "../../../theme.js";
import { useAuth } from '../../../AuthContext.js';

const ProductsList = () => {
  const { filteredGender, setFilteredGender, filteredProducts, setFilteredProducts } = useAuth()

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={'30px'} width="100%">
      <Grid container spacing={2} maxWidth="1200px" mt={'20px'}>
        {filteredProducts.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={product.id} display="flex" justifyContent="center">
            <Card 
            component={Link}
            to={`/products/${product.id}`}
            sx={{
              width: '242px',
              height: '358px',
              gap: '16px',
              borderRadius: '10px',
              border: `1px solid ${colors.border}`,
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflow: "hidden",
              textDecoration: "none",
              padding: '6px'
            }}>
              {/* <Box
                component="iframe"
                src={product.src}
                title={`${product.name} Video`}
                sx={{
                  width: '100%',       
                  height: '230px',
                  borderRadius: '8px',
                  border: '1px solid transparent',
                  boxSizing: 'border-box', 
                  overflow: "hidden"
                }}
              ></Box> */}
              <Box                 sx={{
                  width: '100%',       
                  height: '230px',
                  borderRadius: '8px',
                  border: '1px solid transparent',
                  boxSizing: 'border-box', 
                  overflow: "hidden"
                }}>
                <img src={product.img} style={{width: '100%', height: '100%'}}/>
              </Box>
              <CardContent sx={{
                width: '230px',
                height: '94px',
                padding: '0px 10px',
                // paddingLeft: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '8px'
              }}>
                <Typography variant="body1" sx={{ml: '5px', color: '#323232', fontWeight: '600'}}>{product.name}</Typography>
                <Typography variant="body2" sx={{ml: '5px'}}>{product.category}</Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Typography variant="body2" sx={{ml: '5px'}}>{product.price}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/products/${product.id}`}
                    sx={{ minWidth: 'auto', padding: '4px 8px' }}
                  >
                    View More
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductsList;
