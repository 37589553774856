import product_0 from "../../../assets/images/products/prod_0.png"
import product_1 from "../../../assets/images/products/prod_1.png"
import product_2 from "../../../assets/images/products/prod_2.png"
import product_3 from "../../../assets/images/products/prod_3.png"
import product_4 from "../../../assets/images/products/prod_4.png"
import product_5 from "../../../assets/images/products/prod_5.png"
import product_6 from "../../../assets/images/products/prod_6.png"
import product_7 from "../../../assets/images/products/prod_7.png"
import product_8 from "../../../assets/images/products/prod_8.png"
import product_9 from "../../../assets/images/products/prod_9.png"
import product_10 from "../../../assets/images/products/prod_10.png"
import product_11 from "../../../assets/images/products/prod_11.png"
import product_12 from "../../../assets/images/products/prod_12.png"
import product_13 from "../../../assets/images/products/prod_13.png"
import product_14 from "../../../assets/images/products/prod_14.png"

export const products = [
    {
      id: 0,
      name: "Women's Satin Midi Dress",
      category: "Dress",
      price: "$89.00",
      description: "Midi dress made of yarn with 29% viscose. Round neck and no sleeves.",
      gender: "female",
      src:"https://visual-f834e.web.app/?itemId=0&gender=female",
      img: product_0,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [5]
    },
    {
      id: 1,
      name: "Women's Mid Sleeve T-shirt",
      category: "Top",
      price: "$19.00",
      description: "Mid Weight - Slim Fit - Round Neck - Regular Length - Medium Sleeve",
      gender: "female",
      src:"https://visual-f834e.web.app/?itemId=1&gender=female",
      img: product_1,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [6, 7, 8]
    },
    {
      id: 2,
      name: "Women's Short Sleeve T-shirt",
      category: "Top",
      price: "$19.00",
      description: "Mid Weight - Slim Fit - Round Neck - Regular Length - Short Sleeve",
      gender: "female",
      src:"https://visual-f834e.web.app/?itemId=2&gender=female",
      img: product_2,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [6, 7, 8]
    },
    {
      id: 3,
      name: "Women's Luxe Elbow-Sleeve Top",
      category: "Top",
      price: "$89.00",
      description: "A classic made in our new blend jersey selected for its lightweight warmth and buttery handfeel. Fitted. Turtleneck. Straight hem. Fitted. Long sleeves. Hip length.",
      gender: "female",
      src:"https://visual-f834e.web.app/?itemId=3&gender=female",
      img: product_3,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [6, 7, 8]
    },
    {
      id: 4,
      name: "Women's No Sleeve T-shirt",
      category: "Top",
      price: "$19.00",
      description: "Mid Weight - Slim Fit - Round Neck - Regular Length - Short Sleeve",
      gender: "female",
      src:"https://visual-f834e.web.app/?itemId=4&gender=female",
      img: product_4,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [6, 7, 8]
    },
    {
      id: 5,
      name: "Women's Hoodie - short sleeves",
      category: "Top",
      price: "$59.00",
      description: "Sweatshirt made of a cotton blend yarn.",
      gender: "female",
      src:"https://visual-f834e.web.app/?itemId=5&gender=female",
      img: product_5,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [6, 7, 8]
    },
    {
      id: 6,
      name: "Women's Straight Soft Shorts",
      category: "Pants",
      price: "$38.00",
      description: "High-waist shorts with an elastic waistband and straight leg. Seamless hems.",
      gender: "female",
      src:"https://visual-f834e.web.app/?itemId=6&gender=female",
      img: product_6,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [1, 2, 5]
    },
    {
      id: 7,
      name: "Women's Straight Soft Trousers ",
      category: "Pants",
      price: "$50.00",
      description: "High-waist trousers with an elastic waistband and straight leg. Seamless hems.",
      gender: "female",
      src:"https://visual-f834e.web.app/?itemId=7&gender=female",
      img: product_7,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [1, 2, 5]
    },
    {
      id: 8,
      name: "Women's Mid-Length Skirt",
      category: "Skirt",
      price: "$69.00",
      description: "Mid-rise midi skirt with an elastic waistband.",
      gender: "female",
      src:"https://visual-f834e.web.app/?itemId=8&gender=female",
      img: product_8,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [1, 2, 5]
    },
    {
      id: 9,
      name: "Men's Short Sleeve T-shirt",
      category: "Top",
      price: "$29.00",
      description: "Mid Weight - Normal Fit - Round Neck - Regular Length - Short Sleeve",
      gender: "male",
      src:"https://visual-f834e.web.app/?itemId=9&gender=male",
      img: product_9,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [13,14]
    },
    {
      id: 10,
      name: "Men's Short Sleeve T-shirt",
      category: "Top",
      price: "$39.00",
      description: "Mid Weight - Slim Fit - Round Neck - Regular Length - Short Sleeve",
      gender: "male",
      src:"https://visual-f834e.web.app/?itemId=10&gender=male",
      img: product_10,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [13,14]
    },
    {
      id: 11,
      name: "Men's No Sleeve T-shirt",
      category: "Top",
      price: "$29.00",
      description: "Mid Weight - Slim Fit - Round Neck - Regular Length - No Sleeve",
      gender: "male",
      src:"https://visual-f834e.web.app/?itemId=11&gender=male",
      img: product_11,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [13,14]
    },
    {
      id: 12,
      name: "Men's Hoodie - short sleeves",
      category: "Top",
      price: "$69.00",
      description: "Sweatshirt made of a cotton blend yarn.",
      gender: "male",
      src:"https://visual-f834e.web.app/?itemId=12&gender=male",
      img: product_12,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [13,14]
    },
    {
      id: 13,
      name: "Mens Waist Shorts",
      category: "Pants",
      price: "$19.00",
      description: "Shorts made of stretch cotton. Elasticated waistband with drawstring",
      gender: "male",
      src:"https://visual-f834e.web.app/?itemId=13&gender=male",
      img: product_13,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [9, 10, 12]
    },
    {
      id: 14,
      name: "Mens Waist Trousers",
      category: "Pants",
      price: "$39.00",
      description: "Trousers made of stretch cotton. Elasticated waistband with drawstring.",
      gender: "male",
      src:"https://visual-f834e.web.app/?itemId=14&gender=male",
      img: product_14,
      sizes: ["XS","S","M","L","XL","XXL","XXXL"],
      mixMatch: [9, 10, 12]
    },
  ];