import React from 'react';
import { Box, Typography } from '@mui/material';
import CustomAppBar from '../CustomAppBar';
import ProductsList from './components/ProductsList';
import { isBrowser, isChrome } from 'react-device-detect';

function HomePage() {

    return (
        <>
        {isBrowser && isChrome ?
          <>
          <CustomAppBar/>
            <ProductsList/>
          </>
        :
          <>
            <CustomAppBar/>
            <Box
              sx={{
                width: "100vw",
                height: "calc(100vh - 64px)",
                overflow: "hidden",
                display: "flex", 
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center", 
                padding: 2, 
                boxSizing: "border-box",
                gap: '30px'
              }}
            >
              <Typography variant="h1" sx={{ m: 0, fontSize: '24px' }}>Oops!</Typography>
              <Typography variant="h4" sx={{ m: 0, width: '300px', fontSize: '17px', lineHeight: '24px'  }}>This feature is available only on desktop or laptop devices.</Typography>
              <Typography variant="h4" sx={{ m: 0, width: '300px', fontSize: '13px', lineHeight: '20px', color: "#637381"}}>Please access our site from a desktop or laptop computer, in Chrome browser.<br></br> Thank you!</Typography>
            </Box>
          </>
        }
        </>

        // <> 
        //   <CustomAppBar/>
        //   <ProductsList/>
        // </> 
    );
}

export default HomePage;
